import React, { useEffect } from "react";
// import { useForm } from '@fuse/hooks';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
// import "./TableDash.scss";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../Service/API/index.js";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Header from '../Header/Header.js'
// import Footer from '../Footer/Footer.js'
import Modal from "@material-ui/core/Modal";
import { InlineWidget } from "react-calendly";
import "./index.css";

import { useHistory } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Trash from "../../Image/trash.png";
import Reschedule from "../../Image/recycle.png";
import moment from "moment";
import close from "../../Image/close.png";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
    width: "100%",
  },
});

//Modal Box Material UI
function getModalStyle() {
  const top = 50;
  const left = 50;
  const height = "120vh";

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: `100vh`,
  };
}

export default function TableDash(props) {
  let history = useHistory();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [page, setPage] = React.useState(0);
  const [OpenRes, setOpenRes] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [lotNumber, setlotNumber] = React.useState("");
  const [listData, setlistData] = React.useState([]);
  const [tableerrordata, setTableErrordata] = React.useState();
  const [totalcount, setTotalCount] = React.useState();
  const [RescheduleList, SetRescheduleList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [Cancelopen, CancelsetOpen] = React.useState(false);
  const [AlertCancelopen, AlertCancelsetOpen] = React.useState(false);
  const [AlertReschduleopen, AlertReschduleSetopen] = React.useState(false);

  const [vaccinationId, SetvaccinationId] = React.useState("");

  const [uuid, setUUID] = React.useState(false);

  useEffect(() => {
    setlistData(props.location.state.tabledata);
    searchTable();
  }, [page, rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const searchTable = () => {
    let data = {
      lotNumber: lotNumber,
      pageNo: page,
    };
  };
  const EnterKeySearch = (evt) => {
    if (evt.keyCode == 13) {
      searchTable();
    }
  };
  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const OpenModal = (row) => {
    setOpen(true);

    console.log(row.eventInviteeUUID);
    setUUID(row.eventInviteeUUID);
    // api.rescheduleAPI(row, (response) => {
    //   console.log("response", response);
    // });
  };

  const Backer = () => {
    props.history.push("/appointmentchanges")
  }

  const handleClose = () => {
    setOpen(false);

    console.log("props", props.location.state)


    let data = {
      confirmationCode:
        props.location.state.searchqueryconfirmationcode === ""
          ? ""
          : props.location.state.searchqueryconfirmationcode,
      firstName:
        props.location.state.searchqueryfirstname === ""
          ? ""
          : props.location.state.searchqueryfirstname,
      lastName:
        props.location.state.searchquerylastname === ""
          ? ""
          : props.location.state.searchquerylastname,
      dob:
        props.location.state.searchquerydob === ""
          ? ""
          : props.location.state.searchquerydob,
    };

    console.log("closedata", data);

    setIsLoaded(true);
    api.SearchRecordAPI(data, (response) => {
      console.log("response", response);
      console.log("responseinfo", response.info);

      if (response.statusCode === 200) {
        setlistData(response.info);
        setIsLoaded(false);
      } else if (response.errorCode == 400) {
        toast.error(response.errorMessage);
        setIsLoaded(false);
      }
    });
  };
  const openCancelModal = (row) => {
    setUUID(row.eventInviteeUUID);
    CancelsetOpen(true);
    // api.CancelAPI(row, (response) => {
    //   console.log("response", response);
    // });
  };

  const handleCloseCanel = (row) => {
    CancelsetOpen(false);

    let data = {
      confirmationCode:
        props.location.state.searchqueryconfirmationcode === ""
          ? ""
          : props.location.state.searchqueryconfirmationcode,
      firstName:
        props.location.state.searchqueryfirstname === ""
          ? ""
          : props.location.state.searchqueryfirstname,
      lastName:
        props.location.state.searchquerylastname === ""
          ? ""
          : props.location.state.searchquerylastname,
      dob:
        props.location.state.searchquerydob === ""
          ? ""
          : props.location.state.searchquerydob,
    };

    setIsLoaded(true);

    api.SearchRecordAPI(data, (response) => {
      console.log("response", response);
      console.log("responseinfo", response.info);

      if (response.statusCode === 200) {
        setlistData(response.info);
        setIsLoaded(false);
      } else if (response.errorCode == 400) {
        toast.error(response.errorMessage);
        setIsLoaded(false);
      }
    });
  };
  const onMyFrameLoad = (row) => {
    console.log("alert");
  };
  //Cancel alert modal
  const OpenCancelAlert = (row) => {
    SetvaccinationId(row)
    AlertCancelsetOpen(true)
  }
  const CloseCancelAlert = (row) => {
    AlertCancelsetOpen(false)

  }
  // Ṛeschdule alert modal
  const OpenReschedule = (row, vaccinationId) => {
    SetRescheduleList(row)
    SetvaccinationId(vaccinationId)
    AlertReschduleSetopen(true)
  }

  const CloseReschduleAlert = () => {
    AlertReschduleSetopen(false)
  }
  const cancelfun = () => {
    let data = {
      "vaccinationId": vaccinationId,
      "comments": null
    }
    api.CancelAPI(data, (response) => {
      let data = {
        confirmationCode:
          props.location.state.searchqueryconfirmationcode === ""
            ? ""
            : props.location.state.searchqueryconfirmationcode,
        firstName:
          props.location.state.searchqueryfirstname === ""
            ? ""
            : props.location.state.searchqueryfirstname,
        lastName:
          props.location.state.searchquerylastname === ""
            ? ""
            : props.location.state.searchquerylastname,
        dob:
          props.location.state.searchquerydob === ""
            ? ""
            : props.location.state.searchquerydob,
      };

      setIsLoaded(true);

      api.SearchRecordAPI(data, (response) => {
        console.log("response", response);
        console.log("responseinfo", response.info);

        if (response.statusCode === 200) {
          setlistData(response.info);
          setIsLoaded(false);
          AlertCancelsetOpen(false)
          toast.success(response.info)
        } else if (response.errorCode == 400) {
          toast.error(response.errorMessage);
          setIsLoaded(false);
          AlertCancelsetOpen(false)
        }
      });

    });
  }
  const reschdulealert = (row) => {
    AlertReschduleSetopen(false)
    let data = {
      "vaccinationId": vaccinationId,
      "comments": null
    }
    api.CancelAPI(data, (response) => {
      let data = {
        confirmationCode:
          props.location.state.searchqueryconfirmationcode === ""
            ? ""
            : props.location.state.searchqueryconfirmationcode,
        firstName:
          props.location.state.searchqueryfirstname === ""
            ? ""
            : props.location.state.searchqueryfirstname,
        lastName:
          props.location.state.searchquerylastname === ""
            ? ""
            : props.location.state.searchquerylastname,
        dob:
          props.location.state.searchquerydob === ""
            ? ""
            : props.location.state.searchquerydob,
      };

      setIsLoaded(true);

      api.SearchRecordAPI(data, (response) => {
        if (response.statusCode === 200) {
          setlistData(response.info);
          setIsLoaded(false);
          var b = window.btoa(JSON.stringify(RescheduleList));

          if (JSON.parse(localStorage.getItem("labdetails")).id === 15) {
            window.open(
              process.env.REACT_APP_STT_URL + "&id=" + b,
              "_blank"
            );
          } else if (JSON.parse(localStorage.getItem("labdetails")).id === 14) {
            window.open(
              process.env.REACT_APP_STX_URL + "&id=" + b,
              "_blank"
            );
          } else if (JSON.parse(localStorage.getItem("labdetails")).id === 23) {
            window.open(
              process.env.REACT_APP_STJURL + "&id=" + b,
              "_blank"
            );
          }

        } else if (response.errorCode == 400) {
          toast.error(response.errorMessage);
          setIsLoaded(false);
        }
      });

    });
  }
  // ----------------------------------------------------------------------------------------------//

  return (
    <>
      <ToastContainer />

      {/* <Header /> */}
      {/* Reschedule */}
      {AlertReschduleopen == true ? (

        <Modal
          open={AlertReschduleopen}
          onClose={CloseReschduleAlert}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >



          <div
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="modalBox"
          >
            <div className="modal-content">
              <img src={close} className="closeIcn"
                onClick={CloseReschduleAlert}
              />
              <div className="btnAdjHeader">
                To reschedule, this appointment will be cancelled and a new appointment can be booked.
                Are you sure you want to continue? You will not be able to undo this change.
      </div>
              <div className="btnAdjHeader">
                <button className="subBtn10"
                  onClick={reschdulealert}
                >
                  Yes
        </button>

                <button
                  className="subBtn10"
                  onClick={CloseReschduleAlert}
                >
                  No
        </button>
              </div>
            </div>
          </div>
        </Modal>



      ) : null}
      {/* Cancel */}
      {AlertCancelopen == true ? (

        <Modal
          open={AlertCancelopen}
          onClose={CloseCancelAlert}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >



          <div
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="modalBox"
          >
            <div className="modal-content">
              <img src={close} className="closeIcn"
                onClick={CloseCancelAlert}
              />
              <div className="btnAdjHeader">
                Are you sure you want to continue? You will not be able to undo this change.
              </div>
              <div className="btnAdjHeader">
                <button className="subBtn10"
                  onClick={cancelfun}
                >
                  Yes
                </button>

                <button
                  className="subBtn10"
                  onClick={CloseCancelAlert}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal>



      ) : null}
      {open == true ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className="paperModal">
            <div className="backer">
              <button className="NextBtn1" onClick={handleClose}>Return to Gateway​</button>
            </div>
            {/* <img src={close} className="closeIcnnew" onClick={handleClose} /> */}

            <div className="closeHolder" onClick={handleClose}></div>
            <div className="calendlyHolder">
              {/* <InlineWidget
                                    // Staging
                                    url="https://calendly.com/reschedulings/EA3RDUCDTINCCYYN"
                                    // Prod
                                    // url="https://calendly.com/vivax-stt/st-thomas-covid-19-vaccination-appointment"
                                    //   prefill={{
                                    //     email: email == "N/A" ? "placeholder_email@hummingbird.health" : email,
                                    //     firstName: firstName,
                                    //     lastName: lastName,
                                    //   }}

                                    styles={{ position: "relative", minWidth: '320px', height: '650px', }}
                                /> */}
              <div style={{ height: "100vh" }}>
                <iframe
                  src={"https://calendly.com/reschedulings/" + uuid}
                  width="100%"
                  height="100%"
                  frameborder="0"
                  sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                ></iframe>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}

      {Cancelopen == true ? (
        <Modal
          open={Cancelopen}
          onClose={handleCloseCanel}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className="paperModal">
            {/* <img
              src={close}
              className="closeIcnnew"
              onClick={handleCloseCanel}
            /> */}
            <button className="closeIcnnew" onClick={handleCloseCanel}>Return to Gateway​</button>

            <div className="closeHolder" onClick={handleCloseCanel}></div>
            <div className="calendlyHolder">
              {/* <InlineWidget
                                    // Staging
                                    url="https://calendly.com/cancellations/DAZRGCXUS6V5ILFE"
                                    // Prod
                                    // url="https://calendly.com/vivax-stt/st-thomas-covid-19-vaccination-appointment"
                                    //   prefill={{
                                    //     email: email == "N/A" ? "placeholder_email@hummingbird.health" : email,
                                    //     firstName: firstName,
                                    //     lastName: lastName,
                                    //   }}

                                    styles={{ position: "relative", minWidth: '320px', height: '650px', }}
                                /> */}
              <div style={{ height: "100vh" }}>
                <iframe
                  onload={onMyFrameLoad}
                  src={"https://calendly.com/cancellations/" + uuid}
                  width="100%"
                  height="100%"
                  frameborder="0"
                ></iframe>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}

      <Grid container spacing={3} className="gridcontent">

        {isLoaded === true ? (
          <Paper
            className={classes.root}
            style={{
              // marginTop: "2em",
              width: "90%",
              margin: "2em auto",
              boxShadow: "unset !important",
            }}
          >
            <div className="loaderAdj">
              <CircularProgress />
            </div>
          </Paper>
        ) : (
          <Grid item xs={12} md={12} sm={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <header style={{ marginLeft: '30px' }}>
                <h1 style={{ paddingTop: '20px' }}>Search Results​</h1>
              </header>
              <div className="backer">
                <p onClick={Backer} className="linkBack" >Back </p>
              </div>
            </div>


            <Paper
              className={classes.root}
              style={{
                // marginTop: "2em",
                width: "94%",
                margin: "2em auto",
              }}
            >
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>DOB (MM-DD-YYYY)</TableCell>
                      <TableCell>Confirmation Code</TableCell>
                      <TableCell>Appointment Date / Time </TableCell>
                      <TableCell>Previously Received Dose (Yes/No)​</TableCell>
                      <TableCell>Created Via</TableCell>
                      <TableCell>Status</TableCell>

                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listData !== undefined ? (
                      listData.map((row) => (
                        <TableRow>
                          <TableCell>{row.patientFirstName}</TableCell>
                          <TableCell>{row.patientLastName}</TableCell>
                          {/* <TableCell>{row.patientDob}</TableCell> */}

                          <TableCell>
                            {moment(row.patientDob).format("MM-DD-YYYY")}
                          </TableCell>

                          <TableCell>{row.confirmationCode}</TableCell>
                          {/* <TableCell>{row.eventStartDate}</TableCell> */}

                          <TableCell>
                            {moment(row.eventStartDate).format("MM-DD-YYYY")}{" "}
                            {row.eventStartTime}
                          </TableCell>
                          <TableCell>
                            {" "}
                            {row.previousReceivedVaccineDose}
                          </TableCell>
                          <TableCell>
                            {" "}
                            {row.createdIn == "Vaccination" ? "Public" :
                              row.createdIn == "Vaccination-Internal" ? "Internal"
                                : row.createdIn == "Vaccination CSV" ? "CSV"
                                  : row.createdIn == "Vaccination-Mobile" ? "Mobile"
                                    : ''}
                          </TableCell>
                          <TableCell>
                            {" "}
                            {row.status === null ? "-" : row.status}
                          </TableCell>
                          <TableCell>
                            <div className="imageHolders">
                              {row.eventStartDate >=
                                moment(new Date()).format("yyyy-MM-DD") &&
                                row.status != "Cancelled" &&
                                row.createdIn != "Vaccination CSV"
                                ?
                                <div title="Reschedule">
                                  <img
                                    style={{ width: '18px', marginLeft: '10px' }}
                                    src={Reschedule}
                                    onClick={() => OpenModal(row)}
                                  />
                                </div>
                                :
                                <div title="Reschedule">

                                </div>

                              }

                              {row.eventStartDate >=
                                moment(new Date()).format("yyyy-MM-DD") &&
                                row.status != "Cancelled"
                                && row.createdIn == "Vaccination CSV"
                                ?
                                <div title="Reschedule">
                                  <img
                                    src={Reschedule}
                                    style={{ width: '18px', marginLeft: '10px' }}
                                    onClick={() => OpenReschedule(row, row.vaccinationId)}
                                  />
                                </div>
                                :
                                <div title="Reschedule">
                                </div>
                              }

                              {row.eventStartDate >=
                                moment(new Date()).format("yyyy-MM-DD") && row.status != "Cancelled" && row.createdIn != "Vaccination CSV"
                                ?
                                <div title="Cancel">
                                  <img
                                    src={Trash} style={{ width: '18px', marginLeft: '10px' }}
                                    onClick={() => openCancelModal(row)}
                                  />
                                </div>
                                :
                                <div title="Cancel">
                                </div>

                              }

                              {row.eventStartDate >=
                                moment(new Date()).format("yyyy-MM-DD") && row.status != "Cancelled" && row.createdIn == "Vaccination CSV"
                                ?
                                <div title="Cancel">
                                  <img
                                    src={Trash} style={{ width: '18px', marginLeft: '10px' }}
                                    onClick={() => OpenCancelAlert(row.vaccinationId)}
                                  />
                                </div>
                                :
                                <div title="Cancel">
                                </div>
                              }
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell>
                          {/* style={{padding:"2em 0"}}> */}
                          {tableerrordata !== undefined ? tableerrordata : ""}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={totalcount !== undefined ? totalcount : ""}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              /> */}
            </Paper>
          </Grid>
        )}
      </Grid>
      {/* <Footer /> */}
    </>
  );
}
