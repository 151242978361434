import React, { Component } from "react";
import "../Login/index.css";
import Logo from "../../Image/applogo.png";
import Logotext from "../../Image/apptext.png";
import Logo1 from "../../Image/logo1.png";
import Shield from "../../Image/VITEMA.png";
import Info from "../../Image/info.png";
import api from '../../Service/API/index.js';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from '../Footer/Footer.js'
import Tooltip from '@material-ui/core/Tooltip';



class LoginClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      RadioLoginType: "email",
      inputemail: "",
      inputmobile: "",
      Inputvalid: false,
      from: "noselect"
    };
  }
  componentDidMount() {
    if (localStorage.getItem('features')) {
      if (window.location.pathname == "/") {
        this.setState({isLoaded:true});
        window.location.replace("/appointmentchanges");
      }
    }
  }
  // Radio button onchange
  handleChange(event) {
    if(event.target.value=="email")
    {
      this.setState({
        inputmobile:'',
        RadioLoginType: event.target.value,
      });
    }
   else if(event.target.value=="mobile")
   {
     this.setState({
      inputemail:'',
       RadioLoginType: event.target.value,
     });
   }
  }
  // Email input onchange
  HandleInputChange(e) {
    this.setState({
      inputemail: e.target.value,
    });
  }
  SubmitLogin() {

    if (this.state.inputmobile.trim() != "" || this.state.inputemail.trim() != "") {
      if ((/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(this.state.inputemail.trim())) == false && this.state.inputemail.trim() != '') {
        toast.error("Enter the correct Email-id")
      }
      else {
        let data

        if (this.state.RadioLoginType === "email") {
          data = {
            "email": this.state.inputemail.trim(),
            "messageCode": ""
          }
        }
        else {
          data = {
            "mobileNumber": "+" + this.state.inputmobile,
            "messageCode": ""
          }
        }
        this.setState({
          isLoaded: true
        })
        api.LoginAPI(data, (response) => {

          if (response.statusCode === 200) {

            localStorage.setItem("messageId", (response.messageId));

            if (response.info.length === 0) {

              this.props.history.push(
                {
                  pathname: '/otp',
                  state: this.state.inputemail ? this.state.inputemail : this.state.inputmobile,
                  state: { from: this.state.from }
                }
              )

              this.setState({
                isLoaded: false
              })

            } else {

              localStorage.setItem("infoArray", JSON.stringify(response.info));

              this.props.history.push(
                {
                  pathname: '/role',
                  state: this.state.inputemail ? this.state.inputemail : this.state.inputmobile
                }
              )
              this.setState({
                isLoaded: false
              })

            }

          }
          else if (response.errorCode === 400) {
            toast.error(response.errorMessage)
            this.setState({
              isLoaded: false
            })
          }



        })
      }

    }


    else if (this.state.inputmobile == "" || this.state.inputemail == "") {
      if (this.state.RadioLoginType === "email") {
        toast.error("Please enter an email address")
      }
      else {
        toast.error("Please enter a Mobile Number")
      }
    }


  }
  mobileNumberChange(number) {
    this.setState({ inputmobile: number });
  }
  SignInC(evt) {
    if (evt.keyCode == 13) {
      this.SubmitLogin()
    }
  }
  // ---------------------------------------------------------------------------------------------------------
  render() {
    // window.scrollTo(0, 0);

    return (
      <div className="loginAdj">
        <ToastContainer />
        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) :
          <div className="loginBG">
            <div className="loginLogo">
              <div>
                <img src={Logo} className="logo" />
              </div>

              <div>
              <img src={Logotext} className="logotext" />
                </div>
              {/* <br /> */}
            </div>

            <div className="welcome">
            <div style={{display:'flex'}}>
            <div>
                <img src={Shield} style={{width:'90px',marginLeft:'10px'}} className="logo1" />
              </div>
              <div style={{marginTop:'-10px'}}>
                <img src={Logo1} className="logo1" />
              </div>
              </div>
              <h3>Welcome to the</h3>
              <h4>Covid-19 Vaccine Scheduling Gateway​</h4>
            </div>

            <div className="inputDiv">
              <div style={{ display: "flex", marginLeft: "1%" }}>
                <div>
                  <p>Please enter your email address to login</p>
                </div>
                <div>
                  <Tooltip title="This is where you will receive your secure code for log-in" placement="top">
                    <img src={Info} className="Info" />
                  </Tooltip>

                </div>
              </div>

              {/* <div style={{ display: "flex" }}>
                <div>
                  <input
                    type="radio"
                    value="email"
                    checked={this.state.RadioLoginType === "email"}
                    onChange={this.handleChange.bind(this)}
                    defaultChecked
                    id="email"
                  />{" "}
                  <label for="email">Email</label>
                </div>
               
              </div> */}

              <div>
                {this.state.RadioLoginType === "email" ? (
                  <input
                    type="text"
                    className="textInput"
                    placeholder="Enter your email address"
                    style={{width:'82%',marginLeft:'1%',paddingLeft:'0px'}}
                    value={this.state.inputemail}
                    onChange={(e) =>
                      this.setState({ inputemail: e.target.value })
                    }
                    onKeyUp={this.SignInC.bind(this)}
                  />
                ) : (
                    <PhoneInput
                      country={"us"}
//                       onlyCountries={['us']}
                      placeholder={"Mobile Number"}
//                       disableDropdown={true}
                      onChange={this.mobileNumberChange.bind(this)}
                      value={this.state.inputmobile}
                      onKeyUp={this.SignInC.bind(this)}
                      countryCodeEditable={false}
                      style={{width: '95%', marginLeft: '1%'}}
                    />
                  )}
              </div>

              {this.state.Inputvalid === true && this.state.RadioLoginType === "email" ?
                <div className="validError">
                  Enter the Email-id
            </div>
                : this.state.Inputvalid === true && this.state.RadioLoginType === "mobile" ?
                  <div className="validError">
                    Enter the Mobile number
          </div>
                  : ''
              }
              <div className="centerAdj">
                <button className="subBtn" onClick={this.SubmitLogin.bind(this)}>Submit</button>
              </div>
            </div>
          </div>
        }
        {/* <div className="footeerlogin" >
          <Footer />

        </div> */}
      </div >

    );
  }
}
export default LoginClass;
