import React, { Component } from "react";
import "./index.css";
import DatePicker from "react-datepicker";
import eye from "../../Image/eye.png";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../Service/API/index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import '../Createappointment/create.css'
export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: undefined,
      dob: null,
      confirmationCode: "",
      firstname: "",
      lastname: "",
      tabledata: [],
      isLoaded: false,
      searchquerydob: "",
      searchqueryconfirmationcode: "",
      searchqueryfirstname: "",
      searchquerylastname: "",
    };
  }

  onSearch() {
    if(this.state.confirmationCode.trim() ===""&&this.state.firstname.trim()===""&&this.state.lastname.trim()==="" && this.state.dob===null){
      toast.error("Please enter the search value")
    }
    else{
      this.setState({
        isLoaded: true
      })
      let data = {
        confirmationCode: this.state.confirmationCode.trim(),
        firstName: this.state.firstname.trim(),
        lastName: this.state.lastname.trim(),
        dob:
          this.state.dob === null
            ? ""
            : moment(this.state.dob).format("yyyy-MM-DD"),
      };
      api.SearchRecordAPI(data, (response) => {
        console.log("response", response);
        console.log("responseinfo", response.info);
  
        if (response.statusCode === 200) {
          this.props.history.push({
            pathname: "/appointmentchanges/table",
            state: {
              tabledata: response.info,
              searchquerydob:
                this.state.dob === null
                  ? ""
                  : moment(this.state.dob).format("yyyy-MM-DD"),
              searchqueryconfirmationcode: this.state.confirmationCode.trim() === "" ? "" : this.state.confirmationCode.trim(),
              searchqueryfirstname: this.state.firstname.trim() === "" ? "" : this.state.firstname.trim(),
              searchquerylastname: this.state.lastname.trim() === "" ? "" : this.state.lastname.trim(),
            },
          });
        } else if (response.errorCode == 400) {
          this.setState({
            isLoaded: false
          })
          toast.error(response.errorMessage);
        }
      });

    }
    
  }

  SignInC(evt) {
    if (evt.keyCode == 13) {
      this.onSearch();
    }
  }

  selectDate(e) {
    let dateS = e.target.value.split("-");
    this.setState(
      { date: dateS[1] + "-" + dateS[2] + "-" + dateS[0] },
      () => { }
    );
  }
  // Dob change
  handleDateChange(date) {
    this.setState({ dob: date });
  }
  render() {

    return (
      <div className="container">
        <ToastContainer />

        {this.state.isLoaded === true ? (
          <Paper
            // className={classes.root}
            style={{
              // marginTop: "2em",
              width: "90%",
              margin: "2em auto",
              boxShadow: "unset !important",
            }}
          >
            <div className="loaderAdj">
              <CircularProgress />
            </div>
          </Paper>
        ) : (

          <div>


            <header>
              <h1>Search Existing Appointments​</h1>
            </header>

            <div className="loginform">
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                  <label className="labelStyle">Date of Birth (MM/DD/YYYY)</label>
                  <MuiPickersUtilsProvider
                    style={{ paddingTop: "30px", width: '90%' }}
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      placeholder="MM/DD/YYYY"
                      clearable
                      value={this.state.dob}
                      format="MM/dd/yyyy"
                      maxDate={new Date()}
                      required
                      autoOk
                      // onKeyUp={this.EnterKeynext.bind(this)}
                      onChange={(date) => this.handleDateChange(date)}
                      onKeyUp={this.SignInC.bind(this)}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                  <div
                    style={{ flex: 1, display: "flex", flexDirection: "column" }}
                  >
                    <label className="labelStyle">Confirmation Code</label>
                    <input
                      type="text"
                      className="textInput"
                      value={this.state.confirmationCode}
                      onChange={(e) =>
                        this.setState({ confirmationCode: e.target.value })
                      }
                      style={{ width: "82%", marginLeft: "1%", paddingLeft: "0px" }}
                      onKeyUp={this.SignInC.bind(this)}
                    />
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", paddingTop: "2em" }}>
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                  <label className="labelStyle">First Name</label>
                  <input
                    type="text"
                    className="textInput"
                    value={this.state.firstname}
                    onChange={(e) => this.setState({ firstname: e.target.value })}
                    style={{ width: "82%", marginLeft: "1%", paddingLeft: "0px" }}
                    onKeyUp={this.SignInC.bind(this)}
                  />
                </div>

                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                  <label className="labelStyle">Last Name</label>
                  <input
                    type="text"
                    className="textInput"
                    value={this.state.lastname}
                    onChange={(e) => this.setState({ lastname: e.target.value })}
                    style={{ width: "82%", marginLeft: "1%", paddingLeft: "0px" }}
                    onKeyUp={this.SignInC.bind(this)}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button className="NextBtn" onClick={this.onSearch.bind(this)}>
                Search
          </button>
            </div>
          </div>
        )}

      </div>
    );
  }
}

export default index;
