import React, { Component } from "react";
import "./index.css";
import Logo from "../../Image/applogo.png";
import Logotext from "../../Image/apptext.png";
import profile from "../../Image/logout.png";
import close from "../../Image/close.png";


class HeaderClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  Logout() {
    localStorage.clear()
    window.location.replace("/");
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  render() {
    return (
      <div>
        {this.state.open === true ? (
          <div
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="modalBox"
          >
            <div className="modal-content">
              <img src={close} className="closeIcn"  onClick={this.handleClose.bind(this)}/>
              <div className="btnAdjHeader">
              Are you sure you want to logout?
              </div>
              <div className="btnAdjHeader">
                <button className="subBtn10" onClick={this.Logout.bind(this)}>
                  Yes
                </button>

                <button
                  className="subBtn10"
                  onClick={this.handleClose.bind(this)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        ) : null}

        <div className="header">
          <div className="logoheader">
            <div>
            <img src={Logo} className="logo" />
            </div>
            <div>
            <img src={Logotext} className="logotext" />
            </div>
           
          
            
          </div>

          {!window.location.pathname.match(/otp/) && !window.location.pathname.match(/role/) ? (
            <>
              <div>
                <span className="facilityName">
                  {
                  "Covid-19 Vaccine Hotline Gateway - " +  JSON.parse(localStorage.getItem("labdetails")).hotlineName
                      
                  }
                </span>
              </div>

              <div className="floatRighter">
                <span>Welcome {localStorage.getItem("username")} !</span>

                <img
                  src={profile}
                  onClick={this.handleOpen.bind(this)}
                  className="logoutImg"
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
export default HeaderClass;
