export const API_URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = API_URL + "/vaccination/hotline/login";
export const POST_OTP = API_URL + "/auth/otp/send";
export const OTP_URL = API_URL + "/vaccination/hotline/otp/validate";
export const GET_PATIENT_RACE = API_URL + "/patient/dropdown/list";
export const NEW_POST_OTP = API_URL + "/auth/otp/resend";
export const REPORTING_TABLE_URL = API_URL + "/report/module/fetchdata";
export const REPORTING_TABLE_VACCINATION_URL =
  API_URL + "/report/module/update/vaccination";
export const REPORTING_TABLE_EXPORT_URL = API_URL + "/report/module/exportdata";
export const RESCHEDULE = API_URL + "/vaccination/hotline/reschedule";
export const CANCEL = API_URL + "/vaccination/hotline/cancel";

// Search record
export const SEARCH_RECORD = API_URL + "/vaccination/hotline/search";
// Refresh Token
export const REFRESH_TOKEN = API_URL + "/auth/refresh/token?refreshToken=";