import React from "react";
import Login from "../src/Components/Login/index.js";
import Otp from "../src/Components/Login/OTP.js";
import RoleLogin from "../src/Components/Login/roleLogin.js";
import Menu from "./Components/Menu/Menu.js";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import Calendlycomponent from '../src/Components/Createappointment/calendly'
function App() {
  if (localStorage.getItem('features') == "menu") {
    if (window.location.pathname == "/otp" || window.location.pathname == "/" || window.location.pathname == "/role") {
      window.location.replace("/appointmentchanges");
    }
  }

  const [sessionTimeOut, setsessionTimeOut] = React.useState(false);

  React.useEffect(() => {

    const x = document.cookie;
    if (x === "") {
      localStorage.clear();
    }
    if (sessionTimeOut === true) {

      localStorage.clear()
      window.location.replace("/");
    }
  }, [sessionTimeOut]);

  const handleOnIdle = (event) => {
    if (event === undefined) {
      setsessionTimeOut(true);
    }
  };

  const handleOnActive = (event) => {
  };

  const handleOnAction = (e) => {
    if (e !== undefined) {
      setsessionTimeOut(false);
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return (
    <div className="App">
      <Router>
        <Route path="/" exact component={Login} />
        <Route path="/role" exact component={RoleLogin} />
        <Route path="/otp" exact component={Otp} />
        <Route path="/appointmentchanges" exact component={Menu} />
        <Route path="/reporting-dashboard" exact component={Menu} />
        <Route path='/create' exact component={Menu} />
        <Route path='/appointmentchanges/table' exact component={Menu} />
        <Route path='/calendly' exact component={Calendlycomponent} />
      </Router>
    </div>
  );
}

export default App;
