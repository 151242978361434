import React from "react";

import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";

function TableHeader({ handleSort, orderBy, order, setOrder }) {
  const createSortHandler = (id) => {
    handleSort(id);
    setOrder(order === "asc" ? "desc" : "asc");
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center"></TableCell>
        <TableCell>
          <TableSortLabel
            active={orderBy === "date"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("date")}
          >
            Date
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "slot"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("slot")}
          >
            Slot
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "lastName"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("lastName")}
          >
            Last Name
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "firstName"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("firstName")}
          >
            First Name
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "dob"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("dob")}
          >
            DOB
          </TableSortLabel>
        </TableCell>

        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "mobileNumber"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("mobileNumber")}
          >
            Mobile Number
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "vaccinationConfirmationCode"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("vaccinationConfirmationCode")}
          >
            Confirmation Code
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "receivedVaccineDose"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("receivedVaccineDose")}
          >
            Previously received vaccine dose
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">Disability assistance</TableCell>
        <TableCell align="center">Disability assistance notes</TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "author"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("author")}
          >
            Appointment Author
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "email"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("email")}
          >
            Email
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "receivedSms"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("receivedSms")}
          >
            Can Receive SMS?
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "createdOn"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("createdOn")}
          >
            Appointment Created
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy === "vaccineName"}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("vaccineName")}
          >
            Vaccine Name
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
