import React, { Component } from "react";
import Header from "../Header/index.js";
import OTPimg from "../../Image/otp.png";
import "./index.css";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from '../Footer/Footer'
class OTPclass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otpVerify: "",
      Inputvalid: false,
      resendOTP: "",
      isLoaded: false,
      messageId: localStorage.getItem("messageId"),
    };
  }
  componentDidMount() {
    if (localStorage.getItem('features')) {
      if (window.location.pathname == "/otp") {
        this.setState({isLoaded:true});
        window.location.replace("/appointmentchanges");
      }
    }
    this.setState({
      resendOTP: this.props.location.state,
    });
  }
  SubmitOTP() {
    if (this.state.otpVerify != "") {
      this.setState({
        Inputvalid: false,
      });
      let data = {
        otp: this.state.otpVerify,
      };
      this.setState({
        isLoaded: true,
      });
      api.OTPAPI(data, (response) => {
        if (response.statusCode === 200) {
          this.setState({
            isLoaded: false,
          });

          document.cookie = "closed=true";

          localStorage.setItem("accessToken",response.accessToken);
          localStorage.setItem("refreshToken", response.refreshToken);
          localStorage.setItem("features", response.features);
          localStorage.setItem("testTypes", JSON.stringify(response.testTypes));
          localStorage.setItem("username", response.user.username);
          localStorage.setItem("labdetails", JSON.stringify(response.lab));
          this.props.history.push("/appointmentchanges");
        } else if (response.errorCode === 400) {
          toast.error(response.errorMessage);
          this.setState({
            isLoaded: false,
          });
        }
      });
    } else {
      this.setState({
        Inputvalid: true,
        isLoaded: false,
      });
    }
  }
  resend() {
    let data = {
      messageId: this.state.messageId,
      messageCode: "resendTest",
    };

    this.setState({
      isLoaded: true,
    });
    api.newResendOTPAPI(data, (response) => {
      if (response.statusCode === 200) {
        this.setState({
          isLoaded: false,
        });
        toast.success(
          "Enter the verification code we just sent to your email number"
        );
      }
    });
  }

  Backer() {
    if (this.props.location.state.from === "noselect") {
      this.props.history.push("/");
    } else if (this.props.location.state.from === "select") {
      this.props.history.push("/role");
    }
  }
  SignInC(evt) {
    if (evt.keyCode == 13) {
      this.SubmitOTP();
    }
  }
  render() {
    return (
      <div>
        <Header />
        <ToastContainer />
        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div className="otpDiv">
              <div>
                <img src={OTPimg} className="otpSec" />
              </div>
              <div style={{ color: "#D3D7D8", marginTop: "50px" }}>
                Enter the verification code we just sent to <br />
                your email
              </div>
              <div>
                <input
                  type="number"
                  className="textInput1"
                  maxLength={8}
                  value={this.state.otpVerify}
                  onChange={(e) => this.setState({ otpVerify: e.target.value })}
                  style={{ textAlign: "center" }}
                  onKeyUp={this.SignInC.bind(this)}
                />
              </div>
              <div
                style={{
                  color: "#6FE2E9",
                  marginTop: "40px",
                  cursor: "pointer",
                }}
                onClick={this.resend.bind(this)}
              >
                RESEND
              </div>
              {this.state.Inputvalid === true ? (
                <div className="validError">Enter the OTP</div>
              ) : (
                ""
              )}
              <div className="btnAdj">
                <button className="subBtn1" onClick={this.Backer.bind(this)}>
                  Back
                </button>

                <button className="subBtn1 ml" onClick={this.SubmitOTP.bind(this)}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        {/* <Footer/> */}
      </div>
    );
  }
}
export default OTPclass;
