import React, { Component } from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import SplitPane from "react-split-pane";
import AppointmentChanges from "../Appointmentchanges/index";
import "./index.css";
import Calendericon from "../../Image/dailyupdate.png";
import Logotext from "../../Image/apptext.png";
import TableComponent from "../TableComponent/index";
import ReportTable from "../Reporting/TableContainer";
//add

const styles = {
  cursor: "col-resize",
  margin: "0px",
  height: "100%",
};

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routestate: true,
      open: false,
      id: "",
    };
  }
  componentDidMount() {}
  //
  OpenCreate() {
    console.log("red",JSON.parse(localStorage.getItem("labdetails")).id)
    if (JSON.parse(localStorage.getItem("labdetails")).id === 15) {
      window.open(process.env.REACT_APP_STT_URL, "_blank");
    } else if (JSON.parse(localStorage.getItem("labdetails")).id === 14) {
      window.open(process.env.REACT_APP_STX_URL, "_blank");
    } else if (JSON.parse(localStorage.getItem("labdetails")).id === 23) {
      console.log("URL", process.env.REACT_APP_STT_URL);
      window.open(process.env.REACT_APP_STJURL, "_blank");
    } else if (JSON.parse(localStorage.getItem("labdetails")).id === 113) {
      window.open(process.env.REACT_APP_MCHSTT_URL, "_blank");
    }else if (JSON.parse(localStorage.getItem("labdetails")).id === 117) {
      window.open(process.env.REACT_APP_MCHSTX_URL, "_blank");
    }
  }
  render() {
    let arrayNavigation = [
      {
        name: "Appointment Lookup & Updates​",
        link: "/appointmentchanges",
        img: "patientsearch",
        valid: "Appointment",
      },
      {
        name: "Vaccination Schedules",
        link: "/reporting-dashboard",
        img: "patientsearch",
        valid: "Reporting Module",
      },
    ];

    let changeValue = [];
    let changeValue1 = [localStorage.getItem("features")];

    const newArr = [...changeValue1, "Appointment"];
    if (newArr) {
      changeValue = newArr.toString().split(",");
      arrayNavigation = arrayNavigation.filter((x) => {
        if (changeValue.includes(x.valid)) {
          return x;
        }
      });
    } else {
      localStorage.clear();
      window.location.replace("/");
    }

    const pathname = "";
    return (
        <div className="sideNav">
          <Router>
            <SplitPane
                split="vertical"
                minSize={300}
                defaultSize={300}
                resizerStyle={styles}
            >
              <menu>
                {arrayNavigation.map((x, index) => (
                    <div
                        className="selectedLink"
                        onClick={() => {
                          this.setState({ routestate: false });
                        }}
                    >
                      <NavLink
                          to={x.link}
                          exact
                          activeClassName="selected"
                          key={index}
                      >
                        <div className="menuAdj">
                          <div>
                            <img src={`/Image/${x.img}.png`} className="menuImg" />
                          </div>
                          <div>{x.name}</div>
                        </div>
                      </NavLink>

                      {/* } */}
                    </div>
                ))}

                <div>
                  <div className="menuAdj" onClick={this.OpenCreate.bind(this)}>
                    <div>
                      <img src={Calendericon} className="menuImg" />
                    </div>
                    <div>
                      Create Appointment
                      <i
                          style={{ marginLeft: "20px", fontSize: "16px" }}
                          class="fa fa-external-link"
                          aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </menu>
              <div>
                <Route
                    exact
                    path="/appointmentchanges"
                    render={(props) => (
                        <AppointmentChanges
                            routestate={this.state.routestate}
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path="/appointmentchanges/table"
                    render={(props) => (
                        <TableComponent
                            routestate={this.state.routestate}
                            {...props}
                        />
                    )}
                />
                <Route
                    exact
                    path="/reporting-dashboard"
                    render={(props) => (
                        <ReportTable routestate={this.state.routestate} {...props} />
                    )}
                />
              </div>
            </SplitPane>
          </Router>
          {/* <Footer /> */}
        </div>
    );
  }
}

export default SideNav;
