import React, { useEffect } from 'react';
import { InlineWidget } from "react-calendly";
// import Header from './Header.js'
// import Footer from './Footer.js'
import { useHistory } from "react-router-dom";

export default function Calendlycomponent(props) {
  const [firstName, setfirstname] = React.useState("")
  const [lastName, setlastname] = React.useState("")
  const [email, setemail] = React.useState("")
  let history = useHistory();
  const handleClose = () => {
    if (localStorage.getItem("Quuery") == "true") {
      history.push("/?type=internal");
    }
    else {
      history.push("/");
    }
  };
  // Did mount
  useEffect(() => {
    // setfirstname(props.location.state.firstName)
    // setlastname(props.location.state.lastName)
    // setemail(props.location.state.email)
  }, []);

  function isCalendlyEvent(e) {
    return e.data.event &&
      e.data.event.indexOf('calendly') === 0;
  };

  window.addEventListener(
    'message',
    function (e) {
      if (isCalendlyEvent(e)) {
      }
    }
  );



  return (
    <>
      {/* <Header /> */}
      {/* <div className="bannerHolder">

        <div className="bannerContent">
          <h1>St. Croix Covid-19 Vaccine Scheduling</h1>
          <p className="welcomeP">Welcome, you can schedule your first or second dose of the Covid-19 vaccine here.</p>
        </div>
     
      </div>
      <h3 className="candelyhead">We’re almost done! Please select a day and time slot that works for you.</h3> */}
      <div className="">
        <p className="btnback"
          onClick={handleClose}
        >
          <span> <i className="fa fa-arrow-left"></i> </span>
          <span>Go Back</span>
        </p>
      </div>
      <div className="calendlyHolder">
        <InlineWidget
          url={process.env.REACT_APP_CALENDLY_URL}
          prefill={{
            email: email == "N/A" ? "placeholder_email@hummingbird.health" : email,
            firstName: firstName,
            lastName: lastName,
          }}

          styles={{ position: "relative", minWidth: '320px', height: '650px', }}
        />
      </div>

      {/* <Footer /> */}
    </>
  );
}
