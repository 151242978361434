import axios from "axios";
import * as API from "../API-URL/index.js";
let testType;
export default {
  getTesttype() {
    return testType;
  },
  setTesttype(testObject) {
    testType = testObject;
  },
  // Login api
  LoginAPI(data, res) {
    axios
      .post(API.LOGIN_URL, data)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  // OTP
  OTPAPI(data, res) {
    axios
      .get(API.OTP_URL + "?otp=" + data.otp)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  // //post -otp -lab select

  PostOTPAPI(data, res) {
    axios
      .post(API.POST_OTP, data)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  // new resend OTP
  newResendOTPAPI(data, res) {
    axios
      .post(
        API.NEW_POST_OTP +
          "?messageId=" +
          data.messageId +
          "&messageCode=" +
          data.messageCode,
        data,
        data
      )
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  SearchRecordAPI(data, res) {
    axios
      .post(API.SEARCH_RECORD, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  rescheduleAPI(data, res) {
    axios
      .post(API.RESCHEDULE, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  CancelAPI(data, res) {
    axios
      .post(API.CANCEL, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  GetRaceAPI(res) {
    axios
      .get(API.GET_PATIENT_RACE, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },

  REPORTAPI(data, res) {
    axios
      .post(API.REPORTING_TABLE_URL, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  REPORTEXPORTAPI(data, res) {
    axios
      .post(API.REPORTING_TABLE_EXPORT_URL, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
            res(response);
      })
      .catch((error) => {
        res(error.response);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  REPORTPOSTAPI(data, res) {
    axios
      .post(API.REPORTING_TABLE_VACCINATION_URL, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
};

// PostOTPAPI(data, res) {
//     axios
//       .post(API.POST_OTP, data)
//       .then((response) => {
//         res(response.data);
//       })
//       .catch((error) => {
//         res(error.response.data);
//         if (error.response.data.errorMessage == "Token expired.") {
//           RefreshTokenapi(true);
//         }
//       });
//   },

function RefreshTokenapi(data) {
  if (data == true) {
    axios
      .get(API.REFRESH_TOKEN + localStorage.getItem("refreshToken"), {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          // console.log("refresh token",response)
          window.location.reload();
          localStorage.setItem("accessToken", response.data.accessToken);
        }
      })
      .catch((error) => {});
  }
}
