import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import api from "../../Service/API/index.js";
import { toast } from "react-toastify";
import { TableRow, TableCell } from "@material-ui/core";

function Row({ dataItems, data, fetchDetails }) {
  const {
    date,
    slot,
    firstName,
    lastName,
    dob,
    vaccinationConfirmationCode,
    author,
    receivedSms,
    mobileNumber,
    email,
    receivedVaccineDose,
    vaccineName,
    createdOn,
    vaccinationId,
    vaccinationStatus,
    disability,
    disabilityOthers,
  } = dataItems;

  const onCheckData = (id) => {
    const filterdata = data.filter((obj) => obj.vaccinationId === id);
    const value = {
      patientId: filterdata[0].patientId,
      vaccinationId: filterdata[0].vaccinationId,
      status: "Attended",
    };

    if (value) {
      api.REPORTPOSTAPI(value, (response) => {
        if (response.statusCode === 200) {
          fetchDetails();

          toast.success(response.message);
        } else {
          toast.error(response.error);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="center">
          <Checkbox
            id={vaccinationId}
            color="primary"
            checked={vaccinationStatus == "Attended"}
            inputProps={{ "aria-label": "secondary checkbox" }}
            disabled={vaccinationStatus === "Attended"}
            onChange={() => onCheckData(vaccinationId)}
          />
        </TableCell>
        <TableCell align="center">{date}</TableCell>
        <TableCell align="center">{slot}</TableCell>
        <TableCell align="center">{lastName}</TableCell>
        <TableCell align="center">{firstName}</TableCell>
        <TableCell align="center">{dob}</TableCell>
        <TableCell align="center">{mobileNumber}</TableCell>
        <TableCell align="center">{vaccinationConfirmationCode}</TableCell>
        <TableCell align="center">{receivedVaccineDose}</TableCell>
        <TableCell align="center">{disability}</TableCell>
        <TableCell align="center">{disabilityOthers}</TableCell>
        <TableCell align="center">{author}</TableCell>
        <TableCell align="center">{email}</TableCell>
        <TableCell align="center">{receivedSms}</TableCell>
        <TableCell align="center">{createdOn}</TableCell>
        <TableCell align="center">{vaccineName}</TableCell>
      </TableRow>
      <TableRow></TableRow>
    </React.Fragment>
  );
}

export default Row;
