import React, { useState, useEffect } from "react";
import "./report.css";
import {
  Table,
  TableContainer,
  TableBody,
  Box,
  TablePagination,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { utils } from "react-modern-calendar-datepicker";
import DatePicker from "react-modern-calendar-datepicker";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Row from "./Row";
import theme from "./theme";
import moment from "moment";
import TableHeader from "./TableHeader";
import API from "../../Service/API/index.js";
import { Typography } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "@material-ui/core";
import exportIcon from "../../Image/Vector.png";
import exportIcon1 from "../../Image/Vector1.png";
import { CSVLink } from "react-csv";

function ReportTable() {
  const [data, setData] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setloader] = React.useState(false);
  const [totalcount, setTotalCount] = React.useState();
  const [firstNamesearch, setsearchfirst] = React.useState("");
  const [lastNamesearch, setsearchlast] = React.useState("");
  const [searchDob, setsearchDob] = React.useState(null);
  const [confirmCode, setconfirmCode] = React.useState("");
  const [orderByvalue, setorderByvalue] = React.useState(["date"]);
  const refLink = React.useRef(null);
  const refLink1 = React.useRef(null);
  const defaultValue = {
    from: null,
    to: null,
  };
  const [selectedDayRange, setSelectedDayRange] = useState(defaultValue);

  const fetchDetails = () => {
    console.log(orderByvalue);
    setloader(true);
    let QueryData = {
      limit: 10,
      pageNo: page,
      sortBy: orderByvalue,
      // sortBy: orderBy ? orderBy : "date",
      sortIn: order,
      firstName: firstNamesearch.trim() ? firstNamesearch.trim() : null,
      lastName: lastNamesearch.trim() ? lastNamesearch.trim() : null,
      dob: searchDob ? moment(searchDob).format("yyyy-MM-DD") : null,
      confirmationCode: confirmCode.trim() ? confirmCode.trim() : null,
      searchFrom: selectedDayRange.from
        ? `${selectedDayRange.from.year}-${
            selectedDayRange.from.month < 10
              ? "0" + selectedDayRange.from.month
              : selectedDayRange.from.month
          }-${
            selectedDayRange.from.day < 10
              ? "0" + selectedDayRange.from.day
              : selectedDayRange.from.day
          }`
        : null,
      searchTo: selectedDayRange.to
        ? `${selectedDayRange.to.year}-${
            selectedDayRange.to.month < 10
              ? "0" + selectedDayRange.to.month
              : selectedDayRange.to.month
          }-${
            selectedDayRange.to.day < 10
              ? "0" + selectedDayRange.to.day
              : selectedDayRange.to.day
          }`
        : null,
    };
    API.REPORTAPI(QueryData, (res) => {
      if (res.statusCode === 200) {
        setData(res.info);
        setTotalCount(res.totalCount);
        setloader(false);
      } else {
        setloader(false);
        setData([]);
        setTotalCount("");
      }
    });
  };

  //=== Reporting file Timestamp function ===//
  const reportselectfile = () => {
    let d = new Date();
    let dformat = `${Math.round(new Date().getTime() / 1000)}`;
    console.log("getCurrentDate : ", dformat);
    return "Reporting-data-" + dformat + ".csv";
  };
  const reportallfile = () => {
    let d = new Date();
    let dformat = `${Math.round(new Date().getTime() / 1000)}`;
    console.log("getCurrentDate : ", dformat);
    return "Reporting-all-data-" + dformat + ".csv";
  };

  const exportData = (val) => {
    let QueryData = {
      limit: 10,
      pageNo: page,
      sortBy: orderByvalue,
      sortIn: order,
      firstName: firstNamesearch.trim() ? firstNamesearch.trim() : null,
      lastName: lastNamesearch.trim() ? lastNamesearch.trim() : null,
      dob: searchDob ? moment(searchDob).format("yyyy-MM-DD") : null,
      confirmationCode: confirmCode.trim() ? confirmCode.trim() : null,
      exportAllData: val?val:false,
      searchFrom: selectedDayRange.from
        ? `${selectedDayRange.from.year}-${
            selectedDayRange.from.month < 10
              ? "0" + selectedDayRange.from.month
              : selectedDayRange.from.month
          }-${
            selectedDayRange.from.day < 10
              ? "0" + selectedDayRange.from.day
              : selectedDayRange.from.day
          }`
        : null,
      searchTo: selectedDayRange.to
        ? `${selectedDayRange.to.year}-${
            selectedDayRange.to.month < 10
              ? "0" + selectedDayRange.to.month
              : selectedDayRange.to.month
          }-${
            selectedDayRange.to.day < 10
              ? "0" + selectedDayRange.to.day
              : selectedDayRange.to.day
          }`
        : null,
    };

    API.REPORTEXPORTAPI(QueryData, (res) => {
      console.log(res.data)
      let csvContent = "data:text/csv;charset=utf-8," + res.data;
      let headerLine = res.headers;
      console.log(headerLine)
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      let name= val?reportallfile():reportselectfile();
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    });
  };
    
  useEffect(() => {
    fetchDetails();
  }, [page]);

  //--Pagination onchange--//
  const handleChangePage = (event, newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const createSortHandler = (id, ordvalue) => {
    let orderspread = Array.from(new Set([...orderByvalue, id]));
    setorderByvalue([...orderByvalue, id]);
    setOrderBy(id);
    setOrder(order === "asc" ? "desc" : "asc");
    setloader(true);
    setData([]);
    let data = {
      limit: 10,
      pageNo: page,
      sortBy: orderspread,
      sortIn: order === "asc" ? "desc" : "asc",
      firstName: firstNamesearch.trim() ? firstNamesearch.trim() : null,
      lastName: lastNamesearch.trim()? lastNamesearch.trim() : null,
      dob: searchDob ? moment(searchDob).format("yyyy-MM-DD") : null,
      confirmationCode: confirmCode.trim() ? confirmCode.trim() : null,
      exportAllData: false,
      searchFrom: selectedDayRange.from
        ? `${selectedDayRange.from.year}-${
            selectedDayRange.from.month < 10
              ? "0" + selectedDayRange.from.month
              : selectedDayRange.from.month
          }-${
            selectedDayRange.from.day < 10
              ? "0" + selectedDayRange.from.day
              : selectedDayRange.from.day
          }`
        : null,
      searchTo: selectedDayRange.to
        ? `${selectedDayRange.to.year}-${
            selectedDayRange.to.month < 10
              ? "0" + selectedDayRange.to.month
              : selectedDayRange.to.month
          }-${
            selectedDayRange.to.day < 10
              ? "0" + selectedDayRange.to.day
              : selectedDayRange.to.day
          }`
        : null,
    };

    API.REPORTAPI(data, (res) => {
      if (res.statusCode === 200) {
        setloader(false);
        setData(res.info);
        setTotalCount(res.totalCount);
      } else {
        setloader(false);
        setData([]);
        setTotalCount("");
      }
    });
  };
  
  //----SearchBy handle change----//
  const searchHandlefirst = (e) => {
    setsearchfirst(e.target.value);
  };
  const searchHandlelast = (e) => {
    setsearchlast(e.target.value);
  };
  const searchDobHandleChange = (date) => {
    setsearchDob(date);
  };
  const searchHandleconfirm = (e) => {
    setconfirmCode(e.target.value);
  };

  //===Date format function===//
  const formatDateInput = () => {
    if (!selectedDayRange.from) return "";
    if (!selectedDayRange.to) return "";
    const toDate = selectedDayRange.to;
    const fromDate = selectedDayRange.from;
    const toYear = toDate.year.toString();
    const toMonth = toDate.month.toString().padStart(2, 0);
    const toDay = toDate.day.toString().padStart(2, 0);
    const fromYear = fromDate.year.toString();
    const fromMonth = fromDate.month.toString().padStart(2, 0);
    const fromDay = fromDate.day.toString().padStart(2, 0);
    return `${moment(fromMonth)
      .format("MMM")
      .toLocaleUpperCase()} ${fromDay},${fromYear} - ${moment(toMonth)
      .format("MMM")
      .toLocaleUpperCase()} ${toDay},${toYear}`;
  };

  //---Searchby onClick ---//
  const SearchByValue = (e) => {
    setPage(0);
    if (selectedDayRange.from && selectedDayRange.to) {
      fetchDetails();
      
    } else {
      toast.error("Please select the date range");
    }
  };

  const SignInC = (evt) => {
    if (evt.keyCode == 13) {
      SearchByValue();
    }
  };

  const Resetbyvalue = () => {
    setSelectedDayRange({
      from: null,
      to: null,
    });
    setPage(0);
    setTotalCount("0");
    setloader(true);
    setOrder("desc");
    setOrderBy([]);
    setsearchfirst("");
    setsearchlast("");
    setsearchDob(null);
    setconfirmCode("");
    let QueryData = {
      limit: 10,
      pageNo: page,
      sortBy: ["date"],
      sortIn: "desc",
      searchFrom: null,
      searchTo: null,
    };
    API.REPORTAPI(QueryData, (res) => {
      if (res.statusCode === 200) {
        setloader(false);
        setData(res.info);
        setTotalCount(res.totalCount);
      } else {
        setloader(false);
        setData([]);
        setTotalCount("");
      }
    });
  };

  const headers = [
    { label: "Date", key: "date" },
    { label: "Slot", key: "slot" },
    { label: "Last Name", key: "lastName" },
    { label: "First Name", key: "firstName" },
    { label: "DOB", key: "dob" },
    { label: "Mobile Number", key: "mobileNumber" },
    { label: "Confirmation Code", key: "vaccinationConfirmationCode" },
    { label: "Previously received vaccine dose", key: "receivedVaccineDose" },
    { label: "Disability assistance", key: "disability" },
    { label: "Disability assistance notes", key: "disabilityOthers" },
    { label: "Appointment Author", key: "author" },
    { label: "Email", key: "email" },
    { label: "Can Receive SMS?", key: "receivedSms" },
    { label: "Appointment Created", key: "createdOn" },
    { label: " Vaccine Name", key: "vaccineName" },
  ];

  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth={false}>
        <div style={{ width: "74vw", margin: "0 auto" }}>
          <ToastContainer />

          <Grid
            container
            spacing={1}
            justify="center"
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={12} md={12} sm={12}>
              <div className="filter_row">
                <div className="search_filter">
                  <div className="search_label">Search by:</div>
                  <TextField
                    label="First name"
                    type="text"
                    value={firstNamesearch}
                    name="firstName"
                    onChange={searchHandlefirst}
                    onKeyUp={SignInC}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </div>
                <div className="search_filter">
                  <TextField
                    label="Last name"
                    type="text"
                    value={lastNamesearch}
                    name="lastName"
                    onChange={searchHandlelast}
                    onKeyUp={SignInC}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </div>

                <div className="search_filter_date">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{
                        width: "100%",
                      }}
                      clearable
                      onKeyUp={SignInC}
                      value={searchDob}
                      onChange={(date) => searchDobHandleChange(date)}
                      format="MM/dd/yyyy"
                      label="DOB (MM/DD/YYYY)"
                      maxDate={new Date()}
                      autoOk
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="search_filter">
                  <TextField
                    label="Confirmation Code"
                    type="text"
                    value={confirmCode}
                    name="confirmCode"
                    onChange={searchHandleconfirm}
                    onKeyUp={SignInC}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </div>
                <div>
                  <DatePicker
                    inputPlaceholder="Date range *"
                    value={selectedDayRange}
                    onChange={setSelectedDayRange}
                    maximumDate={utils().getToday()}
                    colorPrimary="#00afb9"
                    colorPrimaryLight="#a1eaee"
                    formatInputText={formatDateInput}
                    renderFooter={() => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "1rem 2rem",
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedDayRange({
                              from: null,
                              to: null,
                            });
                          }}
                          style={{
                            border: "#0fbcf9",
                            backgroundColor: "#f07167",
                            color: "#fff",
                            borderRadius: "0.5rem",
                            padding: "0.5rem 1rem",
                          }}
                        >
                          Reset
                        </button>
                      </div>
                    )}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justify="center"
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={12} md={7} sm={12}>
              <div className="searchRow">
                <div style={{ marginRight: "20px" }}>
                  <button className="srchbtn" onClick={SearchByValue}>
                    Search
                  </button>
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <button className="srchbtn" onClick={Resetbyvalue}>
                    Reset
                  </button>
                </div>
              </div>
            </Grid>
            {data.length ? (
              <Grid item xs={12} md={5} sm={12}>
                {/* //export button */}
                <div className="searchRow">
                  <div style={{ marginRight: "20px" }}>
                    <button className="exportBtn" onClick={()=>exportData(false)}>
                      <img
                        src={exportIcon1}
                        alt="export_visible_data"
                        width="15"
                        height="15"
                      />{" "}
                      Export visible data
                    </button>
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    <button className="exportBtn" onClick={()=>exportData(true)}>
                      <img
                        src={exportIcon}
                        alt="export all"
                        width="15"
                        height="15"
                      />{" "}
                      Export all
                    </button>
                  </div>
                </div>
              </Grid>
            ) : (
              <Grid item xs={12} md={5} sm={12}></Grid>
            )}
          </Grid>

          <Box style={{ backgroundColor: "#00afb9" }}>
            <Typography
              align="center"
              style={{ margin: "1px 0", color: "#fff", padding: "10px" }}
            >
              Vaccination Schedules
            </Typography>
          </Box>
          {data ? (
            <>
              {loader === true ? (
                <div className="loaderAdj">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {data.length !== 0 ? (
                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableHeader
                          handleSort={createSortHandler}
                          orderBy={orderBy}
                          order={order}
                          setOrder={setOrder}
                        />
                        <TableBody>
                          {data.map((dataItems) => {
                            return (
                              <Row
                                key={dataItems.patientId}
                                dataItems={dataItems}
                                data={data}
                                fetchDetails={fetchDetails}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                      {totalcount > 10 ? (
                        <TablePagination
                          rowsPerPageOptions={[]}
                          component="div"
                          count={totalcount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                        />
                      ) : (
                        ""
                      )}
                    </TableContainer>
                  ) : (
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} sm={12} align="center">
                        <Typography>No Records Found</Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </>
          ) : (
            <div></div>
          )}
        </div>
      </Container>
    </MuiThemeProvider>
  );
}

export default ReportTable;
